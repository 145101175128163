
.map-view {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-shades-lightGray;
  z-index: 9;

  .map-canvas {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .bw-layer {
      filter: grayscale(100%);
    }
  }

  .layer-controls {
    position: absolute;
    left: 1.5rem;
    bottom: 3.5rem;
    padding: 0.125rem;
    background-color: $color-shades-white;
    z-index: 99;
    touch-action: none;
    cursor: default;
    @include TP {
      bottom: calc(2rem + env(safe-area-inset-bottom));
    }
    @include DS {
      bottom: 2rem;
    }

    .layer {
      display: flex;

      &__minimap {
        position: relative;
        width: 3.5rem;
        height: 3.5rem;

        img {
          width: 100%;
          height: 100%;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            22.5deg,
            transparentize($color-shades-black, 0.2),
            transparentize($color-shades-black, 0.8)
          );
          z-index: 9;
        }

        span {
          position: absolute;
          bottom: 0.25rem;
          left: 0.25rem;
          font-size: 0.75rem;
          font-weight: $font-weight-semi-bold;
          color: $color-shades-white;
          z-index: 99;
        }
      }

      &.active {
        display: none;
      }
    }
  }

  .map-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 1.5rem;
    bottom: 3.5rem;
    gap: 0.25rem;
    z-index: 99;
    touch-action: none;
    @include TP {
      bottom: calc(2rem + env(safe-area-inset-bottom));
    }
    @include DS {
      bottom: 2rem;
    }

    button {
      display: flex;
      background-color: #ffffff;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 2rem;
      height: 2rem;
      border: none;
      cursor: pointer;
    }
    div {
      display: none;
      @include DL {
        display: grid;
      }
    }
    hr {
      margin: 0;
      border: 2px solid #eeeeee;
    }
  }

  .map-footer {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem;
    min-height: 1.5rem;
    z-index: 99;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    @include TP {
      flex-direction: row;
      bottom: calc(0rem + env(safe-area-inset-bottom));
      gap: 0.5rem;
    }
    @include DS {
      bottom: 0;
    }

    &__copyright {
      display: flex;
      justify-content: center;
      padding: 0.25rem 0.5rem;
      background-color: $color-shades-white;
      gap: 0.5rem;
      @include TP {
        justify-content: none;
      }

      p.attribution,
      a,
      button {
        font-family: $font-family-secondary;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $color-shades-black;
      }

      a,
      button {
        font-weight: $font-weight-semi-bold;
        padding-left: 0.5rem;
        border-left: 1px solid $color-shades-darkGray;
        pointer-events: all;
      }
    }

    &__legend {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      background-color: $color-shades-white;
      gap: 0.5rem;
      pointer-events: none;

      p.value {
        font-family: $font-family-secondary;
        font-weight: $font-weight-semi-bold;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $color-shades-black;
      }

      .line {
        flex: 1;
        height: 0.25rem;
        border-radius: 0.125rem;
        background: linear-gradient(
          270deg,
          #FC1016 0%,
          #FFFB3A 33%,
          #5CFF6B 50%,
          #1AFEFE 66%,
          #0216F7 100%,
        );
      }
    }
  }
}