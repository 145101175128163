@use "sass:map";

// COLORS
$colors: (
  hues: (
    "green": hsl(150, 100%, 50%),
    "blue": hsl(190, 100%, 60%),
    "yellow": hsl(60, 90%, 60%),
  ),
  shades: (
    "lightBlack": hsl(240, 10%, 30%),
    "darkGray": hsl(240, 5%, 65%),
    "lightGray": hsl(0, 0%, 93%),
    "white": hsl(0, 0%, 100%),
    "black": hsl(240, 60%, 10%)
  ),
  flag: (
    "notify": hsl(340, 100%, 50%),
  ),
);

$color-hues-orange: hsl(30, 100%, 50%);
$color-hues-blue: hsl(190, 100%, 60%);
$color-hues-yellow: hsl(60, 90%, 60%);
$color-shades-lightBlack: hsl(240, 10%, 30%);
$color-shades-darkGray: hsl(240, 5%, 65%);
$color-shades-lightGray: hsl(0, 0%, 93%);
$color-shades-white: hsl(0, 0%, 100%);
$color-shades-black: hsl(240, 60%, 10%);
$color-flag-notify: hsl(340, 100%, 50%);

// Color map
@function --color($base, $shade: "black") {
  @return map-get(map-get($colors, $base), $shade);
}

//FONTS

// Font familis
$font-family-primary: "IBM Plex Mono", monospace;
$font-family-secondary: "IBM Plex Sans", sans-serif;

// Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

//base font size
$base-font-size-mobile: 14px;
$base-font-size-tablet: 14px;
$base-font-size-desktop: 16px;

// Sizes
$h1-font-size-mobile: 28px;
$h1-font-size-tablet: 42px;
$h1-font-size-desktop: 48px;
$h2-font-size-mobile: 22px;
$h2-font-size-tablet: 28px;
$h2-font-size-desktop: 32px;
$h3-font-size-mobile: 20px;
$h3-font-size-desktop: 26px;
$h4-font-size-mobile: 16px;
$h4-font-size-desktop: 18px;
$h5-font-size-mobile: 14px;
$h5-font-size-desktop: 16px;
$body-font-size-mobile: 16px;
$body-font-size-desktop: 18px;
$span-font-size-mobile: 13px;
$span-font-size-desktop: 14px;
$small-font-size-mobile: 14px;
$small-font-size-desktop: 16px;

@function fontSize($type, $device: "desktop") {
  @return map-get(map-get($font-sizes, $type), $device);
}

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  ms: 0,
  ml: 378px,
  tp: 672px,
  tl: 980px,
  ds: 1200px,
  dl: 1568px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 292px,
  sm: 350px,
  md: 567px,
  lg: 812px,
  xl: 1016px,
  xxl: 1296px,
);

// Grid columns and guttes
//
// Define the number of grid colums and responsive gutters

$grid-columns: 12;
$grid-gutters: (
  xxs: 8px,
  xs: 14px,
  sm: 24px,
  md: 28px,
  lg: 28px,
  xl: 32px,
  xxl: 48px,
);

// Layout and display values
//
// Define the base layout and display values to
// change the alignment of flex items.

$layout-values: flex-start, flex-end, center, space-between, space-around;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex;

// Buttons
//
// For buttons, define text, background, and border color.

$button-padding-y: 11px; // Base is 1px / 0.75rem   (1rem = 16px)
$button-padding-x: 1.5rem; // Base is 24px / 1.5rem    (1rem = 16px)
$button-icon-padding: 8px; // Base is 8px  / 0.5rem    (1rem = 16px)
$button-font-size: 14px; // Base is 14px / 0.875rem  (1rem = 16px)
$button-line-height: 16px; // Base is 16px / 1rem      (1rem = 16px)

$button-sm-padding-y: 10px; // Base is 10px / 0.688rem  (1rem = 16px)
$button-sm-padding-x: 21px; // Base is 21px / 1.313rem  (1rem = 16px)
$button-sm-icon-padding: 6px; // Base is 6px  / 0.438rem  (1rem = 16px)
$button-sm-font-size: 0.875rem; // Base is 14px / 0.875rem  (1rem = 16px)
$button-sm-line-height: 14px; // Base is 14px / 0.875rem  (1rem = 16px)

$button-colors: (
  "black": #000000,
  "white": #ffffff,
  "green": map.get($colors, "hues", "green"),
  "gray": map.get($colors, "shades", "lightGray"),
);

$button-font-weight: $font-weight-semi-bold;
$button-border-radius: 0rem;
$button-border-style: 1px solid map.get($button-colors, "black");
$button-shadow: 0px 3px 0px rgba(69, 69, 84, 0.2);

// Inputs
//
// For inputs, define basic values.

$form-input-height-desktop: 40px;
$form-input-height-mobil: 36px;
$form-input-border-color: $color-shades-black;
$form-input-border-radius: 4px;
$form-input-border-color: --bg-shades-white;

// Border-radius
//
// Basic value of border-radius

$border-radius-value: 8px;
