.page {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
  justify-content: center;
	cursor: default;

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 40rem;
		height: 100%;
		padding: 2rem;
		gap: 3rem;
		@include TP {
			padding: 2rem 3rem;
			height: fit-content;
		}

		&__head {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 3rem;
		}

		&__body {
			display: flex;
			flex-direction: column;
			height: calc(100% - 4rem);
			overflow-y: scroll;
			gap: 3rem;
			@include TP {
				height: fit-content;
				overflow: hidden;
			}

			figure {
				@include TP {
					display: none;
				}
			}

			&__copy {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;

				p {
					font-size: 1rem;
					margin-bottom: 1rem;

					&:last-of-type {
						margin: 0;
					}
				}

				&__partners {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					gap: 1rem;
					@include TP {
						gap: 2rem;
					}

					li {
						width: calc(50% - 1rem);

						a {
							display: flex;
							flex-direction: column;
							align-items: center;
							color: $color-shades-black;
							gap: 0.5rem;
	
							.cover {
								width: 8rem;
								height: 8rem;
								background-size: cover;
							}
	
							span {
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}
