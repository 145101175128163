// Mixin to manage responsive breakpoints
// Media query manager
/*
> 0         MS - Mobile Small
> 378px     ML - Mobile Large
> 672px     TP - Tablet Portrait
> 980px     TL - Tablet Landscape
> 1200px    DS - Desktop Small
> 1568px    DL - Desktop Large
*/

// Breakpoints mixin
//
// Basic mixin of breakpoints. These can be used to configure 
// designs at different screen widths.

@mixin MS {
    @media (min-width: map-get($grid-breakpoints, "ms")) {
        @content;
    }
}

@mixin ML {
    @media (min-width: map-get($grid-breakpoints, "ml")) {
        @content;
    }
}

@mixin TP {
    @media (min-width: map-get($grid-breakpoints, "tp")) {
        @content;
    }
}

@mixin TL {
    @media (min-width: map-get($grid-breakpoints, "tl")) {
        @content;
    }
}

@mixin DS {
    @media (min-width: map-get($grid-breakpoints, "ds")) {
        @content;
    }
}

@mixin DL {
    @media (min-width: map-get($grid-breakpoints, "dl")) {
        @content;
    }
}