html {
  font-size: 14px;
  @include DS {
    font-size: 16px;
  }
}

body {
  font-family: $font-family-primary;
  color: $color-shades-black;
  background-color: $color-shades-white;
  font-display: swap;
  @include TL {
    overflow: hidden;
    height: calc(var(--vh) * 100);
  }

  &.fixed {
    overflow: hidden;
    height: calc(var(--vh) * 100);
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

hr {
  border: 1px solid $color-shades-lightGray;
}

.App {
  display: flex;
  min-height: calc(var(--vh) * 100);
  flex-flow: column wrap;
  overflow: hidden;
  @include TL {
    flex-flow: row wrap;
  }

  main {
    position: relative;
    width: 100%;
    height: calc(var(--vh) * 100);
    @include TL {
      flex: 1;
    }

    &.collapsed {
      width: 100%;
      height: calc(var(--vh) * 70);
      @include TP {
        height: calc((var(--vh) * 100) - 21rem);
      }
      @include TL {
        height: calc(var(--vh) * 100);
      }
    }
  }
}
