.checkbox__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  label {
    flex: 1;
    font-size: 0.875rem;
    font-family: $font-family-secondary;
    line-height: 1.2;
    color: $color-shades-lightGray;

    a {
      color: $color-hues-orange;
      font-weight: $font-weight-medium;
    }
  }

  input[type="checkbox"] {
    position: relative;
    appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $color-shades-lightBlack;
    }

    &:checked:before {
      background-color: $color-hues-orange;
    }
  }
}
