.options-selector {
  display: flex;
  flex-direction: column;
  color: $color-shades-white;
  gap: 1rem;

  &__options {
    display: flex;
    flex-direction: column;

    .option {
      &--wrapper {
        position: relative;
        border: 1px solid transparent;
    
        label {
          cursor: pointer;

          input {
            display: none;
          }
        }
    
        &.active {
          background-color: transparentize($color-hues-orange, 0.8) ;
          border-color: $color-hues-orange;
      
          .option__content {
            color: $color-hues-orange;
            &--icon {
              svg {
                display: block;
              }
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0.75rem;
        min-height: 3rem;
        gap: 1rem;
    
        &--icon {
          position: relative;
          height: 2rem;
          width: 2rem;
          
          svg {
            display: none;
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }
  }
}