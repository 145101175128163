.comingsoon-panel {
	position: absolute;
	left: 1.5rem;
	bottom: 2rem;
	display: flex;
	flex-direction: column;
	width: 25rem;
	padding: 1.5rem;
	background-color: $color-shades-black;
	color: $color-shades-white;
	border-top: 0.25rem solid $color-hues-orange;
	gap: 1.5rem;
	z-index: 999;
	cursor: default;
	@include TP {
		padding: 1.5rem 2rem;
	}

	&__head {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;

		h3 {
			color: $color-hues-orange;
		}
	}

	form {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		.fields {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}

		.form-loader {
			display: block;
			padding: 0.5rem;
			text-align: center;
			border: 1px solid $color-shades-lightBlack;
			background: linear-gradient(to right,
				$color-shades-black 25%,
				$color-shades-lightBlack 50%,
				$color-shades-black 75%
			);
  		background-size: 200% 100%;
			animation: skeletonLoading 1s cubic-bezier(0,.5,.7,.4) infinite;

			span {
				font-family: $font-family-primary;
				font-weight: $font-weight-semi-bold;
				text-transform: uppercase;
				font-size: 0.875rem;
				line-height: 1.5rem;
			}
			
			@keyframes skeletonLoading {
				0% {
					background-position: 100% 0;
				}
				100% {
					background-position: -100% 0;
				}
			}
		}
	}

	&__error {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
		padding: 0.5rem 0.75rem;
		border-left: 0.125rem solid $color-flag-notify;
		background-color: transparentize($color-flag-notify, 0.9);
		color: $color-flag-notify;
	}

	&__success {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem;
		border-radius: 0.25rem;
		background-color: $color-hues-orange;
		color: $color-shades-black;

		svg {
			width: 2rem;
			height: 2rem;
		}

		&__subinfo {
			display: flex;
			flex-direction: row;
			align-items: center;
			border-top: 1px solid $color-shades-lightBlack;
			padding-top: 1rem;
			gap: 1rem;

			.address {
				flex: 1;
				white-space: nowrap;
  			overflow: hidden;
  			text-overflow: ellipsis;
			}

			.unsubscribe {
				color: $color-shades-white;
			}
		}
	}
}
