.cookie {
	position: absolute;
	left: 1.5rem;
	right: 1.5rem;
	bottom: 3.5rem !important;
	display: flex;
	flex-direction: row;
	background-color: $color-shades-white;
	transition: .4s ease;
	z-index: 99;
	@include TP {
		left: calc(50% - 13.5rem);
		right: initial;
		bottom: calc(2rem + env(safe-area-inset-bottom)) !important;
	}

	&.hidden {
		transform: translateY(2rem);
		opacity: 0;
	}

	&__body {
		display: flex;
		flex-direction: row;

		&__icon {
			display: flex;
			padding: 1rem;
			background-color: $color-shades-black;
		
			svg {
				width: 2.5rem;
				height: 2.5rem;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0.5rem 1.25rem;
			gap: 0.25rem;
			@include TP {
				max-width: 18rem;
			}

			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: $color-hues-orange;
				gap: 0.25rem;

				p {
					font-size: 0.875rem;
					line-height: 1;
					font-weight: $font-weight-semi-bold;
				}
		
				svg {
					width: 0.875rem;
					height: 0.875rem;
				}
			}
		}
	}

	&__button {
		background-color: $color-shades-white;

		&:hover {
			background-color: $color-hues-orange;
		}

		svg {
			margin: 1rem;
			width: 2.5rem;
			height: 2.5rem;
		}
	}
}
