@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;600&family=IBM+Plex+Sans:wght@400;500;600&display=swap");

$font-sizes: (
  h1: (
    desktop: calculateRem($base-font-size-desktop, $h1-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $h1-font-size-tablet),
    mobile: calculateRem($base-font-size-mobile, $h1-font-size-mobile),
  ),
  h2: (
    desktop: calculateRem($base-font-size-desktop, $h2-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $h2-font-size-tablet),
    mobile: calculateRem($base-font-size-mobile, $h2-font-size-mobile),
  ),
  h3: (
    desktop: calculateRem($base-font-size-desktop, $h3-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $h3-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $h3-font-size-mobile),
  ),
  h4: (
    desktop: calculateRem($base-font-size-desktop, $h4-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $h4-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $h4-font-size-mobile),
  ),
  h5: (
    desktop: calculateRem($base-font-size-desktop, $h5-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $h5-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $h5-font-size-mobile),
  ),
  body: (
    desktop: calculateRem($base-font-size-desktop, $body-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $body-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $body-font-size-mobile),
  ),
  span: (
    desktop: calculateRem($base-font-size-desktop, $span-font-size-desktop),
    tablet: calculateRem($base-font-size-tablet, $span-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $span-font-size-mobile),
  ),
  small: (
    desktop: calculateRem($base-font-size-desktop, $small-font-size-mobile),
    tablet: calculateRem($base-font-size-tablet, $small-font-size-mobile),
    mobile: calculateRem($base-font-size-mobile, $small-font-size-desktop),
  ),
);

// GLOBAL FONT STYLES
.heading {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: 1.2;

  &--1 {
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    word-spacing: -1rem;
    @include MS {
      font-size: #{fontSize(h1, mobile)};
    }
    @include TP {
      font-size: #{fontSize(h1, tablet)};
    }
    @include DS {
      font-size: #{fontSize(h1, desktop)};
    }
  }

  &--2 {
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    @include MS {
      font-size: fontSize(h2, mobile);
    }
    @include TP {
      font-size: fontSize(h2, tablet);
    }
    @include DS {
      font-size: fontSize(h2, desktop);
    }
  }

  &--3 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-regular;
    text-transform: none;
    @include MS {
      font-size: fontSize(h3, mobile);
    }
    @include TP {
      font-size: fontSize(h3, tablet);
    }
    @include DS {
      font-size: fontSize(h3, desktop);
    }
  }

  &--4 {
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    @include MS {
      font-size: fontSize(h4, mobile);
    }
    @include TP {
      font-size: fontSize(h4, tablet);
    }
    @include DS {
      font-size: fontSize(h4, desktop);
    }
  }

  &--5 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    line-height: 18px;
    text-transform: none;
    @include MS {
      font-size: fontSize(h5, mobile);
    }
    @include TP {
      font-size: fontSize(h5, tablet);
    }
    @include DS {
      font-size: fontSize(h5, desktop);
    }
  }

  &--font-family-secondary {
    font-family: $font-family-secondary;
  }
  &--bold {
    font-weight: $font-weight-medium;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .heading;
}

h1 {
  @extend .heading--1;
}

h2 {
  @extend .heading--2;
}

h3 {
  @extend .heading--3;
}

h4 {
  @extend .heading--4;
}

h5 {
  @extend .heading--5;
}

.body {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  line-height: 1.4;
  text-transform: none;

  @include MS {
    font-size: fontSize(body, mobile);
  }
  @include TP {
    font-size: fontSize(body, tablet);
  }
  @include DS {
    font-size: fontSize(body, desktop);
  }
}

p {
  @extend .body;
}

.span {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  text-transform: none;

  @include MS {
    font-size: fontSize(span, mobile);
  }
  @include TP {
    font-size: fontSize(span, tablet);
  }
  @include DS {
    font-size: fontSize(span, desktop);
  }

  &--bold {
    font-weight: $font-weight-semi-bold;
  }
}

span {
  @extend .span;
}
