.onboarding {
	position: absolute;
	left: 1.5rem;
	right: 1.5rem;
	bottom: 3.5rem !important;
	display: flex;
	flex-direction: row;
	background-color: $color-shades-white;
	transition: .4s ease;
	z-index: 99;
	@include TP {
		left: calc(50% - 12.5rem);
		right: initial;
		bottom: calc(2rem + env(safe-area-inset-bottom)) !important;
	}

	&.hidden {
		transform: translateY(2rem);
		opacity: 0;
	}

	&__body {
		display: flex;
		flex-direction: row;

		&__icon {
			display: flex;
			padding: 1rem;
			background-color: $color-shades-black;
		
			svg {
				width: 2.5rem;
				height: 2.5rem;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0.5rem 1.25rem;
			@include TP {
				max-width: 20.5rem;
			}
		}
	}
}
