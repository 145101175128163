.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $color-shades-white;
  margin-bottom: 2rem;
  overflow-y: auto;
  cursor: default;
  @include TP {
    margin-bottom: 0;
  }
  @include TL {
    width: 30rem;
    height: calc(var(--vh) * 100);
  }
  @include DS {
    width: 35rem;
  }

  &.collapsed {
    max-width: 0rem;
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__infobox {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    gap: 1rem;
    @include TL {
      padding: 2rem 3rem;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 3rem;
    }

    &__infoblock {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @include TP {
        flex-direction: row;
        gap: 3rem;
      }
      @include TL {
        flex-direction: column;
        gap: 1rem;
      }

      .infoblock__item {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        &__icon {
          display: flex;
          padding: 0.5rem;
          border: 0.125rem solid $color-shades-black;
          border-radius: 50%;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }

        &__content {
          flex: 1;

          .value {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
          }
        }
      }
    }

    &__disclaimer {
      width: 100%;
      color: $color-shades-lightBlack;
    }
  }

  &__upsellbox {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    @include TL {
      padding: 0 1.5rem 1.5rem;
    }

    &__upsellblock {
      flex: 1;
      display: flex;
      flex-direction: row;
      border: 1px solid $color-shades-lightGray;
      border-radius: 0.5rem;
      color: $color-shades-black;
      overflow: hidden;

      &:hover {
        border: 1px solid $color-shades-darkGray;

        .panel__upsellbox__upsellblock__cover {
          div {
            transform: scale(1.25);
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;
        gap: 1rem;

        &__header {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }

        p {
          font-size: 1rem;
        }
      }

      &__cover {
        display: flex;
        align-items: flex-end;
        padding: 1.5rem;
        background-size: cover;

        div {
          padding: 0.5rem;
          background-color: $color-shades-white;
          border-radius: 50%;
          transition: .2s ease;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }

  &__upsellbox {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $color-shades-black;
    color: $color-shades-white;
    padding: 1.5rem 2rem;
    gap: 2rem;
    @include TL {
      padding: 2rem 3rem;
    }

    &__head {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h3 {
        color: $color-hues-orange;
      }
  
      p {
        font-size: 1rem;
      }
    }

    &__back {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      color: $color-shades-white;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    form {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .formcontent {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
  
        &--fields {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          
          div {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          }
        }
      }

      .form-loader {
        display: block;
        padding: 0.5rem;
        text-align: center;
        border: 1px solid $color-shades-lightBlack;
        background: linear-gradient(to right,
          $color-shades-black 25%,
          $color-shades-lightBlack 50%,
          $color-shades-black 75%
        );
        background-size: 200% 100%;
        animation: skeletonLoading 1s cubic-bezier(0,.5,.7,.4) infinite;
  
        span {
          font-family: $font-family-primary;
          font-weight: $font-weight-semi-bold;
          text-transform: uppercase;
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
        
        @keyframes skeletonLoading {
          0% {
            background-position: 100% 0;
          }
          100% {
            background-position: -100% 0;
          }
        }
      }
    }

    &__success {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      background-color: $color-hues-orange;
      color: $color-shades-black;
  
      svg {
        width: 2rem;
        height: 2rem;
      }
  
      &__subinfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid $color-shades-lightBlack;
        padding-top: 1rem;
        gap: 1rem;
  
        .address {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
  
        .unsubscribe {
          color: $color-shades-white;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &--cover {
        background-size: cover;
        width: 100%;
        height: 7.5rem;
      }
    }
  }
}
