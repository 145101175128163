input {
	position: relative;
	border: 1px solid $color-shades-black;
  box-sizing: border-box;
  padding: 0 0.75rem;
  font-family: $font-family-secondary;
  font-weight: $font-weight-semi-bold;
  font-size: 0.9375rem;
  color: $color-shades-black;
  background-color: $color-shades-white;

  &[type=text],
  &[type=email],
  &[type=tel] {
    height: 2.5rem;
    width: 100%;

    &::placeholder {
      font-weight: $font-weight-regular;
      color: $color-shades-darkGray;
    }
  
    &:focus {
      outline: none;
      border-color: $color-hues-orange;
    }
  
    &.dark {
      color: $color-shades-white;
      background-color: $color-shades-lightBlack;
      
      &::placeholder {
        color: $color-shades-darkGray;
      }
    }
  }
}
