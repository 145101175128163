.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 1.5rem;
  width: 100%;
  z-index: 99;
  pointer-events: none;
  touch-action: none;

  &.searching {
    .header__brand {
      display: none;
      @include TP {
        display: flex;
      }
    }
  }

  &__brand {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    min-height: 3rem;
    background-color: $color-shades-white;
    gap: 1rem;
    pointer-events: all;
    overflow: hidden;

    &.loading {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.25rem;
        width: 50%;
        background: linear-gradient(to right,
          $color-shades-white 0%,
          $color-hues-orange 75%,
          $color-shades-white 100%,
        );
        animation: pulsate 0.8s infinite;
        transform-origin: 0%;
      }

      @keyframes pulsate {
        0% {
          transform: translateX(-100%) scaleX(1);
        }
        100% {
          transform: translateX(200%) scaleX(0.5);
        }
      }
    }

    &-logo {
      height: 1.5rem;
      cursor: pointer;

      svg {
        width: 3.375rem;
        height: 1.5rem;
      }
    }

    &-city {
      padding-left: 1rem;
      border-left: 1px solid $color-shades-black;

      h4 {
        line-height: 1.5rem;
      }
    }
  }

  &__search {
    background-color: $color-shades-white;
    pointer-events: all;

    form {
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      display: flex;

      label {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          margin: 0.75rem;
        }

        input {
          appearance: none;
          all: unset;
          font-family: $font-family-secondary;
          font-weight: $font-weight-regular;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $color-shades-lightBlack;
          width: 0;
          caret-color: transparent;
          @include TP {
            transition: .2s;
          }

          &::placeholder {
            visibility: hidden;
          }

          & + ul + button {
            display: none;
            transition: .2s;
          }
        }
      }
    }

    &.active {
      input {
        caret-color: $color-shades-black;
        width: calc(100vw - 9rem);
        @include TP {
          width: 20rem;
        }
        @include DS {
          width: 25rem;
        }

        &::placeholder {
          visibility: visible;
        }

        & + ul + button {
          display: contents;
        }
      }
    }

    &__list {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      top: 3rem;
      background-color: $color-shades-white;

      button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $color-shades-white;
        text-decoration: none;
        width: 100%;
        text-align: inherit;
        font-family: $font-family-secondary;
        color: $color-shades-black;
        font-weight: $font-weight-regular;
        font-size: 0.875rem;
        line-height: 1rem;

        & > svg {
          height: 1rem !important;
          width: 1rem !important;
          margin: 0 !important;
          visibility: hidden;
        }

        &:hover {
          background-color: $color-shades-lightGray;

          & > svg {
            visibility: visible;
          }
        }
      }
    }
  }
}
